import { useContext } from "react"

import { ConfigContext } from "@/contexts/ConfigContext"

const useUIConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error("useUIConfig must be used within a ConfigContext")
  }
  return context
}

export default useUIConfig
