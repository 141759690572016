import Divider from "@mui/material/Divider"
import MuiDrawer from "@mui/material/Drawer"
import ListItemButton from "@mui/material/ListItemButton"
import { rgba } from "polished"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

import LogoSymbol from "@/components/logo/LogoSymbol"
import { ItemType } from "@/components/sidebar/SidebarNavListItem"

const LogoWrapper = () => <LogoSymbol />

const BrandIcon = styled(LogoWrapper)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
`

const Drawer = styled(MuiDrawer)``

const Brand = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
`

const Link = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.secondary.over};
  transition: color 0.3s;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
const Item = styled(ListItemButton)<ItemType>`
  && {
    display: flex;
    text-align: center;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    height: 64px;
    svg {
      color: ${(props) => props.theme.sidebar.color};
      font-size: ${(props) => props.theme.sidebar.iconSize};
      width: ${(props) => props.theme.sidebar.iconSize};
      height: ${(props) => props.theme.sidebar.iconSize};
      opacity: 0.8;
      display: block;
      margin: 0 auto;
    }
    span {
      color: ${(props) => props.theme.sidebar.color};
      display: block;
    }
    &.active {
      background-color: ${(props) => props.theme.sidebar.active};
    }
    &:not(.active) {
      background-color: ${(props) => props.theme.sidebar.background};
    }
  }
`

const MenuHeaderWrapper = styled.div`
  padding: 8px 16px 6px 16px;
  background-color: ${(props) => props.theme.sidebar.header.color};
  border-bottom: 1px solid ${(props) => props.theme.sidebar.header.border};
  border-top: 1px solid ${(props) => props.theme.sidebar.header.border};
  border-top-left-radius: 4px 4px;
  border-top-right-radius: 4px 4px;
`

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;
  text-color: ${(props) => props.theme.palette.secondary.over};
`
const MenuDivider = styled(Divider)`
  background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.5)};
  height: 1px;
`
export {
  Brand,
  BrandIcon,
  Drawer,
  Item,
  Link,
  MenuDivider,
  MenuHeader,
  MenuHeaderWrapper,
}
