const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "16px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#737373",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
}

const focusedStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

export { acceptStyle, baseStyle, focusedStyle, rejectStyle }
