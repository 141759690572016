import Paper from "@mui/material/Paper"
import styled from "styled-components"

const drawerWidth = 80

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
  width: ${drawerWidth}px;
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-width: calc(100% - ${drawerWidth}px);
`

const MainContent = styled(Paper)`
  && {
    box-shadow: none;
    border-radius: 0;
  }
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`
export { AppContent, Drawer, MainContent, Root, drawerWidth }
