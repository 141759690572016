import IconAdministration from "@/components/icons/IconAdministration"
import IconClients from "@/components/icons/IconClients"
import IconMemory from "@/components/icons/IconMemory"
import IconProviders from "@/components/icons/IconProviders"
import IconRequests from "@/components/icons/IconRequests"
import IconSettings from "@/components/icons/IconSettings"
import { SidebarItemsType, SidebarMenuType } from "@/types/sidebar"

const menuItems = [
  {
    href: "/requests",
    icon: IconRequests,
    title: "Requests",
    claim: "Request",
    right: "Read",
    menu: [
      {
        title: "Requests.Title",
        href: "/requests",
        claim: "Request",
        right: "Read",
      },
      {
        title: "AdvancedRequestSearch.Title",
        href: "/requestSearch",
        claim: "Request",
        right: "Read",
      },
      { separator: true },
      {
        title: "Configuration",
        claim: "Request",
        right: "Admin",
        subMenu: [
          {
            title: "BusinessLines.Title",
            href: "/businessLines",
            claim: "BusinessLine",
            right: "Read",
          },
          {
            title: "Departments.Title",
            href: "/departments",
            claim: "Department",
            right: "Read",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/providers",
    icon: IconProviders,
    title: "Providers",
    claim: "Provider",
    right: "Read",
    menu: [
      {
        title: "Providers.Title",
        href: "/providers",
        claim: "Provider",
        right: "Read",
      },
      {
        title: "Scheduler.Title",
        href: "/scheduler",
        claim: "Planning",
        right: "Write",
      },
      { separator: true },
      {
        title: "Configuration",
        claim: "Provider",
        right: "Admin",
        subMenu: [
          {
            title: "Teams.Title",
            href: "/teams",
            claim: "Team",
            right: "Read",
          },
          {
            title: "Domains.Title",
            href: "/domains",
            claim: "Domain",
            right: "Read",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/clients",
    icon: IconClients,
    title: "Clients",
    menu: [
      {
        title: "Clients.Title",
        href: "/clients",
        claim: "Client",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Contacts.Title",
        href: "/contacts",
        claim: "Contact",
        right: "Read",
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/tags",
    icon: IconMemory,
    title: "Echo",
    claim: "TmTag",
    right: "Read",
    menu: [
      {
        title: "TmSearch.Title",
        href: "/search",
        claim: "TmSearchCustom",
        right: "Read",
      },
      {
        title: "TmBitexts.Title",
        href: "/bitexts",
        claim: "TmBitext",
        right: "Read",
      },
      {
        title: "TmTermino.Title",
        href: "/terminos",
        claim: "TmTermino",
        right: "Read",
      },
      {
        title: "TmTags.Title",
        href: "/tags",
        claim: "TmTag",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Bulk.Title",
        claim: "BitextImport",
        right: "Admin",
        subMenu: [
          {
            href: "/bitextImports",
            title: "Bulk.BitextImport",
            claim: "BitextImport",
            right: "Admin",
          },
          {
            title: "Bulk.TerminoImport",
            href: "/terminoImports",
            claim: "TerminoImport",
            right: "Admin",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    icon: IconAdministration,
    title: "Administration",
    menu: [
      {
        title: "Users.Title",
        href: "/users",
        claim: "User",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Reports.Title",
        href: "/reports",
        claim: "Report",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Messages.Title",
        href: "/messages",
        claim: "Message",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Calendars",
        claim: "GlobalCalendar",
        right: "Admin",
        subMenu: [
          {
            title: "CalendarTemplates.Title",
            href: "/calendarTemplates",
            claim: "GlobalCalendar",
            right: "Read",
          },
          {
            title: "Activities.ListTitle",
            href: "/activities",
            claim: "PresenceType",
            right: "Read",
          },
        ],
      },
      {
        separator: true,
      },
      {
        title: "Billing",
        claim: "Client",
        right: "Admin",
        subMenu: [
          {
            title: "BillingTypes.Title",
            href: "/billingTypes",
            claim: "BillingType",
            right: "Read",
          },
          {
            title: "TaxSchemas.Title",
            href: "/taxSchemas",
            claim: "TaxSchema",
            right: "Read",
          },
          {
            title: "PaymentTypes.Title",
            href: "/paymentTypes",
            claim: "PaymentType",
            right: "Read",
          },
          {
            title: "InvoiceCurrencies.Title",
            href: "/invoiceCurrencies",
            claim: "InvoiceCurrencie",
            right: "Read",
          },
        ],
      },
      {
        separator: true,
      },
      {
        title: "Processus",
        claim: "Service",
        right: "Read",
        subMenu: [
          {
            title: "WorkTypes.ListTitle",
            href: "/workTypes",
            claim: "WorkType",
            right: "Read",
          },
          {
            title: "Services.ListTitle",
            href: "/services",
            claim: "Service",
            right: "Read",
          },
          {
            title: "WorkflowTemplates.ListTitle",
            href: "/workflowTemplates",
            claim: "WorkflowTemplate",
            right: "Read",
          },
        ] as SidebarMenuType[],
      },
      {
        separator: true,
      },
      {
        title: "GeneralParameters",
        claim: "GeneralParameter",
        right: "Read",
        subMenu: [
          {
            title: "ServiceNote.Title",
            href: "/serviceNote/Show",
            claim: "ServiceNote",
            right: "Read",
          },
        ] as SidebarMenuType[],
      },
      {
        separator: true,
      },
      {
        title: "TaskExecutions.Title",
        href: "/taskExecutions",
        claim: "TaskExecution",
        right: "Admin",
      },
      {
        title: "Errors.Title",
        href: "/errors",
        claim: "Error",
        right: "Admin",
      },
    ] as SidebarMenuType[],
  },
  {
    icon: IconSettings,
    title: "Settings",
    menu: [
      {
        title: "Reports.Title",
        href: "/reports/admin",
        claim: "Report",
        right: "Admin",
      },
      {
        separator: true,
      },
      {
        title: "SecurityRoles.Title",
        href: "/securityRoles",
        claim: "SecurityRole",
        right: "Read",
      },
      {
        title: "SecurityPolicies.Title",
        href: "/securityPolicies",
        claim: "SecurityPolicy",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Settings.Title",
        href: "/settings",
        claim: "Config",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "TagConfigs.Title",
        href: "/tagConfigs",
        claim: "TagConfig",
        right: "Read",
      },
      {
        title: "Notifications.Title",
        href: "/notifications",
        claim: "Notification",
        right: "Read",
      },
    ] as SidebarMenuType[],
  },
] as SidebarItemsType[]

export default menuItems
