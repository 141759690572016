import { ErrorResponse } from "@apollo/client/link/error"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"

import { SnackbackOpenProps } from "@/contexts/SnackbarContext"

export function globalErrorHandler(
  error: ErrorResponse,
  openSnackbar: Dispatch<SetStateAction<SnackbackOpenProps>>,
  t: TFunction<"form" | "auth" | "common" | "enums" | "message", undefined>,
) {
  if (error.networkError) {
    openSnackbar({
      message: `${t("Errors.Apollo.NetworkError")}: "${error.networkError.message}"`,
      severity: "error",
    })
  }

  if (error.graphQLErrors) {
    const errorMessage = error.graphQLErrors
      .map((err) => err.message)
      .join("\n")
    openSnackbar({
      message: `${t("Errors.Apollo.GraphQLError")}: "${errorMessage}"`,
      severity: "error",
    })
  }
}
