// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LP: "LP",
}

export const UNICODE = {
  rightArrow: "\u2192",
}
