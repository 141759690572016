import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { thunk } from "redux-thunk"

import uploadManagerReducer, {
  completeUploadIfAllPartsCompleted,
  setUploadPartStatus,
  uploadManagerSlice,
} from "@/redux/slices/uploadManager"
import { configureUploadManager } from "@/services/uploadManager"
import { TaskStatus } from "@/types/uploadManager"

export const store = configureStore({
  reducer: {
    uploadManager: uploadManagerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
      },
    }).concat(thunk),
})

configureUploadManager({
  concurrency: 5,
  onCompleted: ({ uuid, eTag, partNumber }) => {
    store.dispatch(
      setUploadPartStatus({
        uuid,
        eTag,
        partNumber,
        status: TaskStatus.completed,
      }),
    )
    store.dispatch<any>(completeUploadIfAllPartsCompleted(uuid))
  },
  onError: (_args) => {},
  onRunning: (uuid) => {
    store.dispatch(
      uploadManagerSlice.actions.setUploadStatus({
        uuid,
        status: TaskStatus.running,
      }),
    )
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
