import * as React from "react"

type BreadcrumbContextType = {
  breadcrumbLabel: string
  setBreadcrumbLabel: React.Dispatch<React.SetStateAction<string>>
}

export const BreadcrumbContext = React.createContext<
  BreadcrumbContextType | undefined
>(undefined)

type BreadcrumbProviderProps = {
  children: React.ReactNode
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
}) => {
  const [breadcrumbLabel, setBreadcrumbLabel] = React.useState("")

  const contextValue: BreadcrumbContextType = React.useMemo(() => {
    return {
      breadcrumbLabel,
      setBreadcrumbLabel,
    }
  }, [breadcrumbLabel, setBreadcrumbLabel])

  return (
    <BreadcrumbContext.Provider value={contextValue}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
