export const readJson = (value: any) => {
  if (process.env.NODE_ENV === "development") return value
  if (typeof value === "string") return JSON.parse(value as string)
  return value
}

export const writeJson = (value: any) => {
  if (process.env.NODE_ENV === "development") return value
  if (value) return JSON.stringify(value)
  return value
}
