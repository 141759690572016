import axios from "axios"
import PQueue from "p-queue"

import { getClient } from "@/contexts/GraphqlContext"
import {
  CancelMultipartUploadDocument,
  CancelMultipartUploadMutation,
  CancelMultipartUploadMutationVariables,
  CompleteMultipartUploadDocument,
  CompleteMultipartUploadMutation,
  CompleteMultipartUploadMutationVariables,
  CreateMultipartUploadDocument,
  CreateMultipartUploadMutation,
  CreateMultipartUploadMutationVariables,
  CreateMultipartUploadUrlDocument,
  CreateMultipartUploadUrlMutation,
  CreateMultipartUploadUrlMutationVariables,
  DeleteMultipartUploadDocument,
  DeleteMultipartUploadMutation,
  DeleteMultipartUploadMutationVariables,
} from "@/graphql"

let queue: PQueue

export type QueueUploadPartTaskArgs = {
  uuid: string
  uploadId: string
  guidFileName: string
  file: File
  nbParts: number
  partNumber: number
  partSize: number
}

export type CompletedArgs = {
  uuid: string
  partNumber: number
  eTag: string
}

export type ErrorArgs = {
  uploadId: string
  partNumber: number
}

export type CompletedFunc = (args: CompletedArgs) => void
export type ErrorFunc = (args: ErrorArgs) => void
export type RunningFunc = (uuid: string) => void

export type ConfigOptions = {
  concurrency?: number
  onCompleted: CompletedFunc
  onError: ErrorFunc
  onRunning: RunningFunc
}

let configOptions: ConfigOptions

export const configureUploadManager = (options: ConfigOptions) => {
  configOptions = options
  queue = new PQueue({ concurrency: options.concurrency || 5 })

  queue.on("completed", (args: CompletedArgs) => {
    options.onCompleted(args)
  })

  queue.on("error", (args: ErrorArgs) => {
    options.onError(args)
  })
}

export const createMultipartUpload = async (
  variables: CreateMultipartUploadMutationVariables,
): Promise<{
  uploadId: string
  guidFileName: string
}> => {
  const client = getClient()
  const result = await client.mutate<
    CreateMultipartUploadMutation,
    CreateMultipartUploadMutationVariables
  >({
    mutation: CreateMultipartUploadDocument,
    variables,
  })

  const uploadId = result?.data?.createMultipartUpload?.uploadId
  if (!result || !result.data || !uploadId) {
    throw new Error("createMultipartUpload failed")
  }

  return {
    uploadId,
    guidFileName: result.data.createMultipartUpload.guidFileName,
  }
}

export const createMultipartUploadUrl = async (
  variables: CreateMultipartUploadUrlMutationVariables,
): Promise<string> => {
  const client = getClient()
  const result = await client.mutate<
    CreateMultipartUploadUrlMutation,
    CreateMultipartUploadUrlMutationVariables
  >({
    mutation: CreateMultipartUploadUrlDocument,
    variables,
  })

  const uploadUrl = result?.data?.createMultipartUploadUrl?.uploadUrl
  if (!uploadUrl) throw new Error("createMultipartUploadUrl failed")

  return uploadUrl
}

export const cancelMultipartUpload = async (
  variables: CancelMultipartUploadMutationVariables,
): Promise<string> => {
  const client = getClient()
  const result = await client.mutate<
    CancelMultipartUploadMutation,
    CancelMultipartUploadMutationVariables
  >({
    mutation: CancelMultipartUploadDocument,
    variables,
  })

  const id = result?.data?.cancelMultipartUpload
  if (!id) throw new Error("cancelMultipartUpload failed")

  return id
}

export const deleteMultipartUpload = async (
  variables: DeleteMultipartUploadMutationVariables,
): Promise<string> => {
  const client = getClient()
  const result = await client.mutate<
    DeleteMultipartUploadMutation,
    DeleteMultipartUploadMutationVariables
  >({
    mutation: DeleteMultipartUploadDocument,
    variables,
  })

  const id = result?.data?.deleteMultipartUpload
  if (!id) throw new Error("cancelMultipartUpload failed")

  return id
}

export const completeMultipartUpload = async (
  variables: CompleteMultipartUploadMutationVariables,
): Promise<string> => {
  const client = getClient()
  const result = await client.mutate<
    CompleteMultipartUploadMutation,
    CompleteMultipartUploadMutationVariables
  >({
    mutation: CompleteMultipartUploadDocument,
    variables,
  })

  const id = result?.data?.completeMultipartUpload
  if (!id) throw new Error("completeMultipartUpload failed")

  return id
}

export const queueUploadPartTask = ({
  uuid,
  uploadId,
  guidFileName,
  file,
  nbParts,
  partNumber,
  partSize,
}: QueueUploadPartTaskArgs) => {
  queue.add(async (): Promise<CompletedArgs> => {
    const uploadUrl = await createMultipartUploadUrl({
      uploadId,
      guidFileName,
      partNumber,
    })

    configOptions.onRunning(uuid)

    const index = partNumber - 1
    const start = index * partSize
    const end = (index + 1) * partSize
    const blob = index < nbParts ? file.slice(start, end) : file.slice(start)

    const response = await axios.put(uploadUrl, blob)
    const eTag = response.headers.etag.replaceAll('"', "")

    return {
      uuid,
      partNumber,
      eTag,
    }
  })
}
