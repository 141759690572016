import { useCallback, useState } from "react"
import { useIdleTimer } from "react-idle-timer"

type Props = {
  disabled: boolean
  onIdle: () => void
  signOut: () => Promise<void>
  idleTimeMs?: number
  graceTimeMs?: number
}

const useIdleTimeout = ({
  disabled,
  onIdle,
  signOut,
  idleTimeMs = 3600000,
  graceTimeMs = 600000,
}: Props) => {
  const [isIdle, setIsIdle] = useState(false)
  const handleIdle = useCallback(() => {
    setIsIdle(true)
    signOut().then()
  }, [setIsIdle, signOut])

  const idleTimer = useIdleTimer({
    timeout: idleTimeMs,
    promptBeforeIdle: graceTimeMs,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 1000,
    disabled,
  })

  return {
    isIdle,
    setIsIdle,
    idleTimer,
  }
}

export default useIdleTimeout
