import React from "react"

import { Brand, BrandIcon, Drawer } from "@/components/sidebar/Sidebar.styled"
import Footer from "@/components/sidebar/SidebarFooter"
import SidebarNav from "@/components/sidebar/SidebarNav"
import { SidebarItemsType } from "@/types/sidebar"

export type SidebarProps = {
  PaperProps?: {
    style: {
      width: number
    }
  }
  variant?: "permanent" | "persistent" | "temporary"
  open?: boolean
  onClose?: () => void
  items: SidebarItemsType[]
  hideFooter?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ items, hideFooter, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand>
        <BrandIcon />
      </Brand>
      <SidebarNav items={items} />
      {!hideFooter && <Footer />}
    </Drawer>
  )
}

export default Sidebar
