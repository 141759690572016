import { Breadcrumbs } from "@mui/material"
import styled from "styled-components"

const MuiBreadcrumbs = styled(Breadcrumbs)(({ theme: { palette } }) => ({
  "&& ": {
    marginLeft: "12px",
  },
  "& .MuiTypography-root": {
    color: palette.secondary.over,
  },
  "& .MuiBreadcrumbs-separator": {
    color: palette.primary.main,
  },
  "& a": {
    color: palette.secondary.over,
    textDecoration: "none",
    fontWeight: "bold",
    transition: "color 0.3s",
    "&:hover": {
      color: palette.primary.main,
    },
  },
}))

export default MuiBreadcrumbs
