import { CssBaseline } from "@mui/material"
import React from "react"
import { Outlet } from "react-router-dom"

import GlobalStyle from "@/components/GlobalStyle"
import { MuiAuth } from "@/components/layouts/AuthLayout/AuthLayout.styled"

const AuthLayout: React.FC = () => {
  return (
    <MuiAuth>
      <CssBaseline />
      <GlobalStyle />
      <Outlet />
    </MuiAuth>
  )
}

export default AuthLayout
