import { Link as MuiLink } from "@mui/material"
import { LinkProps } from "@mui/material/Link"
import { forwardRef, MouseEventHandler } from "react"
import { Link as RouterLink } from "react-router-dom"

export interface Props extends LinkProps {
  onClick?:
    | (MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLSpanElement>)
    | undefined
}

export const MyLink = forwardRef<any, any>((props, ref) => (
  <RouterLink
    ref={ref}
    to={props.href}
    style={props.style}
    target={props.target}
  >
    {props.children}
  </RouterLink>
))

const Link = (props: Props) => {
  const { children, onClick, ...rest } = props

  return (
    <MuiLink {...rest} onClick={onClick} component={MyLink}>
      {children}
    </MuiLink>
  )
}

export default Link
