export type UploadTask = {
  uploadId: string
  filePath: string
  fileName: string
  guidFileName: string
  fileType: string
  nbParts: number
}

export enum TaskStatus {
  pending,
  running,
  completed,
  error,
}

export type UploadPartState = {
  partNumber: number
  status: TaskStatus
  nbRetry: number
  eTag: string
}

export type UploadTaskState = {
  task: UploadTask
  status: TaskStatus
  items: UploadPartState[]
  total: number
  completed: number
  pending: number
  error: number
  isReference?: boolean
  isInternal?: boolean
  isMain?: boolean
  isFinal?: boolean
  isClean?: boolean
}

export type UploadManagerState = {
  tasks: Record<string, UploadTaskState>
}

export type AddUploadTaskPayload = {
  uuid: string
  filePath: string
  files: File[]
}

export type SetUploadTaskStatusPayload = {
  uuid: string
  status?: TaskStatus
  completed?: number
  pending?: number
  error?: number
}

export type SetUploadPartStatusPayload = {
  uuid: string
  partNumber: number
  status: TaskStatus
  eTag?: string
  incrementRetry?: boolean
}

export type SetFileInternalPayload = {
  uuid: string
  isInternal: boolean
}

export type SetFileReferencePayload = {
  uuid: string
  isReference: boolean
}

export type SetFileMainPayload = {
  uuid: string
  isMain: boolean
}

export type SetFileFinalPayload = {
  uuid: string
  isFinal: boolean
}

export type SetFileCleanPayload = {
  uuid: string
  isClean: boolean
}
