import { Grow, MenuList, Paper, Popper, Typography } from "@mui/material"
import { ListItemProps } from "@mui/material/ListItem"
import MenuItem from "@mui/material/MenuItem"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Trans } from "react-i18next"

import { Claim, Right } from "@/claims"
import ClaimGuard from "@/components/ClaimGuard"
import {
  Item,
  Link,
  MenuDivider,
  MenuHeader,
  MenuHeaderWrapper,
} from "@/components/sidebar/Sidebar.styled"
import { SidebarMenuType } from "@/types/sidebar"

export type ItemType = {
  activeClassName?: string
  onClick?: () => void
  href?: string
}

export type SidebarNavListItemProps = ListItemProps & {
  className?: string
  href: string
  icon: React.FC<any>
  title: string
  claim: Claim
  right: Right
  menu?: SidebarMenuType[]
}

type MenuItemProps = {
  menuItem: SidebarMenuType
  index: number
  handleMenuClose: () => void
}

const GenerateMenuItem: React.FC<MenuItemProps> = ({
  menuItem,
  index,
  handleMenuClose,
}) => {
  const { title, href, separator, claim, right, subMenu } = menuItem

  if (separator) {
    return <MenuDivider key={`separator-${index}`} variant="middle" />
  }

  const menuItemContent = (
    <Link to={href} key={`menu-item--${index}`}>
      <MenuItem onClick={handleMenuClose}>
        <strong>
          <Trans>{title}</Trans>
        </strong>
      </MenuItem>
    </Link>
  )

  const menuContent = claim ? (
    <ClaimGuard empty claim={claim} right={right} key={`menu-item-${index}`}>
      {menuItemContent}
    </ClaimGuard>
  ) : (
    menuItemContent
  )

  return subMenu ? (
    <MenuItem key={`submenu-item-${index}`}>
      <strong>
        <Trans>{title}</Trans>
      </strong>
      <MenuDivider
        variant="middle"
        orientation="vertical"
        flexItem
        sx={{
          marginLeft: 4,
        }}
      />
      <MenuList>
        {subMenu.map((menuItem, subIndex) => (
          <GenerateMenuItem
            key={`sub-submenu-item-${subIndex}`}
            menuItem={menuItem}
            index={subIndex}
            handleMenuClose={handleMenuClose}
          />
        ))}
      </MenuList>
    </MenuItem>
  ) : (
    menuContent
  )
}

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const { title, href, icon: Icon, menu } = props

  const [isHover, setIsHover] = useState(false)
  const [isHoverMenu, setIsHoverMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const activeClassName = useMemo(() => {
    return isHover || isHoverMenu ? "active" : undefined
  }, [isHover, isHoverMenu])

  const handleMouseEnterItem = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget)
      setIsHover(true)
    },
    [setAnchorEl, setIsHover],
  )

  const handleMouseLeaveItem = useCallback(() => {
    setIsHover(false)
  }, [setIsHover])

  const handleEnterMenu = useCallback(() => {
    setIsHoverMenu(true)
  }, [setIsHoverMenu])

  const handleMenuClose = useCallback(() => {
    setIsHoverMenu(false)
  }, [setIsHoverMenu])

  useEffect(() => {
    if (!isHoverMenu && !isHover) {
      setAnchorEl(null)
      handleMenuClose()
    }
  }, [isHoverMenu, isHover, handleMenuClose, setAnchorEl])

  const theMenu = useMemo(() => {
    if (!menu) return null

    return (
      <MenuList>
        {menu.map((menuItem, index) => (
          <GenerateMenuItem
            menuItem={menuItem}
            index={index}
            key={index}
            handleMenuClose={handleMenuClose}
          />
        ))}
      </MenuList>
    )
  }, [menu, handleMenuClose])

  return (
    <Link to={href}>
      <Item
        className={activeClassName}
        onMouseEnter={handleMouseEnterItem}
        onMouseLeave={handleMouseLeaveItem}
      >
        {Icon && <Icon />}
      </Item>
      {menu && (
        <Popper
          anchorEl={anchorEl}
          open={openMenu}
          role={undefined}
          placement="right-start"
          transition
          sx={{ zIndex: "drawer" }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                onMouseLeave={handleMenuClose}
                onMouseEnter={handleEnterMenu}
              >
                <MenuHeaderWrapper>
                  <MenuHeader>
                    <Typography variant="h5">
                      <Trans>{`Menu.${title}`}</Trans>
                    </Typography>
                    {Icon && <Icon fontSize="small" />}
                  </MenuHeader>
                </MenuHeaderWrapper>
                {theMenu}
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Link>
  )
}

export default SidebarNavListItem
