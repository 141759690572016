import { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import { forwardRef } from "react"

import MuiButton from "@/components/Button/Button.styled"

type ButtonProps = React.PropsWithChildren<MuiButtonProps>

const Button: React.FC<ButtonProps> = forwardRef((props, _ref) => {
  return (
    <MuiButton {...props} role="button">
      {props.children}
    </MuiButton>
  )
})

Button.defaultProps = {
  type: "button",
  variant: "contained",
  disableElevation: true,
  color: "primary",
  size: "small",
  children: "button",
}

export default Button
