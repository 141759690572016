import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import update from "immutability-helper"
import { useSelector } from "react-redux"

import { AppThunk, RootState } from "@/redux/store"
import {
  cancelMultipartUpload,
  completeMultipartUpload,
  createMultipartUpload,
  deleteMultipartUpload,
  queueUploadPartTask,
} from "@/services/uploadManager"
import {
  AddUploadTaskPayload,
  SetFileCleanPayload,
  SetFileFinalPayload,
  SetFileInternalPayload,
  SetFileMainPayload,
  SetFileReferencePayload,
  SetUploadPartStatusPayload,
  SetUploadTaskStatusPayload,
  TaskStatus,
  UploadManagerState,
  UploadPartState,
  UploadTaskState,
} from "@/types/uploadManager"

const initialState: UploadManagerState = {
  tasks: {},
}

const getUploadManagerState = (getState: Function) => {
  return (getState() as RootState).uploadManager
}

const removeUploadTask = (state: UploadManagerState, uuid: string) => {
  state.tasks = update(state.tasks, {
    $unset: [uuid],
  })
}

const selectUploadTasks = (state: RootState) => state.uploadManager.tasks

export const selectUploadedFiles = createSelector(
  [selectUploadTasks, (_state, path) => path],
  (tasks, path) => {
    const result = Object.keys(tasks)
      .map((key) => tasks[key])
      .filter((taskState) => taskState.status === TaskStatus.completed)
      .filter((taskState) => taskState.task.filePath === path)
      .map((taskState) => {
        return {
          fileName: taskState.task.fileName,
          guidFileName: taskState.task.guidFileName,
          isInternal: taskState.isInternal,
          isReference: taskState.isReference,
          isMain: taskState.isMain,
          isFinal: taskState.isFinal,
          isClean: taskState.isClean,
        }
      })

    return result.length ? result : undefined
  },
)

const partSize = 5 * 1024 * 1024 //5Mb
const files: Record<string, File> = {}

/*
  Certain file types are not detected on upload, we try to patch the missing ones that we support
*/
const inferFileType = (fileName: string): string => {
  const extension = fileName.split(".").pop()?.toLowerCase()
  switch (extension) {
    case "tbx":
      return "text/tbx"
    case "tmx":
      return "text/tmx"
    default:
      return ""
  }
}

export const addUploadTaskAsync = createAsyncThunk(
  "uploadManager/addUploadTask",
  async (payload: AddUploadTaskPayload) => {
    if (!payload.files || payload.files.length !== 1)
      throw new Error("Gwaaaaaa! TODO: Handle list and better error")

    const file = payload.files[0]
    const quotient = Math.floor(file.size / partSize)
    const remainder = file.size % partSize
    const nbParts = quotient + (remainder ? 1 : 0)

    const { uploadId, guidFileName } = await createMultipartUpload({
      filePath: payload.filePath,
      fileName: file.name,
      fileType: file.type.length > 0 ? file.type : inferFileType(file.name),
    })
    files[uploadId] = file

    return {
      uuid: payload.uuid,
      uploadId,
      filePath: payload.filePath,
      guidFileName,
      file,
      nbParts,
    }
  },
)

export const completeUploadTaskAsync = createAsyncThunk(
  "uploadManager/completeUploadTask",
  async (uuid: string, { getState }) => {
    const state = getUploadManagerState(getState)
    const uploadTask = selectTask(state, uuid)
    await completeMultipartUpload({
      uploadId: uploadTask.task.uploadId,
      guidFileName: uploadTask.task.guidFileName,
      parts: uploadTask.items.map((p) => {
        return {
          partNumber: p.partNumber,
          eTag: p.eTag,
        }
      }),
    })
    return uploadTask.task.uploadId
  },
)

export const cancelUploadTaskAsync = createAsyncThunk(
  "uploadManager/cancelUploadTask",
  async (uuid: string, { getState }) => {
    const state = getUploadManagerState(getState)
    const uploadTask = selectTask(state, uuid)
    await cancelMultipartUpload({
      uploadId: uploadTask.task.uploadId,
      guidFileName: uploadTask.task.guidFileName,
    })
    return {
      uuid,
      uploadId: uploadTask.task.uploadId,
    }
  },
)

export const deleteUploadTaskAsync = createAsyncThunk(
  "uploadManager/deleteUploadTask",
  async (uuid: string, { getState }) => {
    const state = getUploadManagerState(getState)
    const uploadTask = selectTask(state, uuid)
    await deleteMultipartUpload({
      uploadId: uploadTask.task.uploadId,
      guidFileName: uploadTask.task.guidFileName,
    })
    return {
      uuid,
      uploadId: uploadTask.task.uploadId,
    }
  },
)

const statusCount = (
  uploadTask: UploadTaskState,
  status: TaskStatus,
): number => {
  return uploadTask.items.filter((t) => t.status === status).length
}

export const completeUploadIfAllPartsCompleted =
  (uuid: string): AppThunk =>
  (dispatch, getState) => {
    const state = getUploadManagerState(getState)
    const task = selectTask(state, uuid)
    const completed = statusCount(task, TaskStatus.completed)
    const pending = statusCount(task, TaskStatus.pending)
    const error = statusCount(task, TaskStatus.error)
    const total = task.items.length
    const allDone = completed === total

    dispatch(
      uploadManagerSlice.actions.setUploadStatus({
        uuid,
        status: allDone ? TaskStatus.completed : task.status,
        completed,
        pending,
        error,
      }),
    )

    if (allDone) {
      dispatch(completeUploadTaskAsync(uuid))
    } else if (error && error + completed === total) {
      dispatch(cancelUploadTaskAsync(uuid))
    }
  }

export const selectTask = (state: UploadManagerState, uuid: string) => {
  return state.tasks[uuid]
}

export const useUploadTaskSelector = (uuid: string) => {
  return useSelector((state: RootState) =>
    selectTask(state.uploadManager, uuid),
  )
}

export const uploadManagerSlice = createSlice({
  name: "uploadManager",
  initialState,
  reducers: {
    setUploadStatus: (
      state,
      action: PayloadAction<SetUploadTaskStatusPayload>,
    ) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        status: { $set: action.payload.status || task.status },
        completed: { $set: action.payload.completed || task.completed },
        pending: { $set: action.payload.pending || task.pending },
        error: { $set: action.payload.error || task.error },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    setUploadPartStatus: (
      state,
      action: PayloadAction<SetUploadPartStatusPayload>,
    ) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const itemIndex = task.items.findIndex(
        (t) => t.partNumber === action.payload.partNumber,
      )
      if (itemIndex === -1) return
      const item = task.items[itemIndex]

      const newItem = update(item, {
        status: { $set: action.payload.status },
      })

      if (action.payload.incrementRetry) {
        newItem.nbRetry++
      }

      if (action.payload.eTag) {
        newItem.eTag = action.payload.eTag
      }

      const taskState = update(task, {
        items: { [itemIndex]: { $set: newItem } },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    resetUploadTasks: (state, action: PayloadAction<{ filePath: string }>) => {
      const keys = Object.keys(state.tasks)
        .map((key) => {
          return { task: state.tasks[key], key }
        })
        .filter((kv) => kv.task.status === TaskStatus.completed)
        .filter((kv) => kv.task.task.filePath === action.payload.filePath)
        .map((kv) => {
          return { uploadId: kv.task.task.uploadId, key: kv.key }
        })

      for (const kv of keys) {
        delete files[kv.uploadId]
      }

      state.tasks = update(state.tasks, { $unset: keys.map((kv) => kv.key) })
    },

    setIsInternal: (state, action: PayloadAction<SetFileInternalPayload>) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        isInternal: { $set: action.payload.isInternal },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    setIsReference: (state, action: PayloadAction<SetFileReferencePayload>) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        isReference: { $set: action.payload.isReference },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    setIsMain: (state, action: PayloadAction<SetFileMainPayload>) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        isMain: { $set: action.payload.isMain },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    setIsFinal: (state, action: PayloadAction<SetFileFinalPayload>) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        isFinal: { $set: action.payload.isFinal },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },

    setIsClean: (state, action: PayloadAction<SetFileCleanPayload>) => {
      const task = selectTask(state, action.payload.uuid)
      if (!task) return

      const taskState = update(task, {
        isClean: { $set: action.payload.isClean },
      })
      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addUploadTaskAsync.fulfilled, (state, action) => {
      const items: UploadPartState[] = []
      for (let i = 1; i <= action.payload.nbParts; i++) {
        items.push({
          partNumber: i,
          status: TaskStatus.pending,
          nbRetry: 0,
          eTag: "",
        })
      }
      const taskState: UploadTaskState = {
        task: {
          uploadId: action.payload.uploadId,
          filePath: action.payload.filePath,
          fileName: action.payload.file.name,
          fileType: action.payload.file.type,
          nbParts: action.payload.nbParts,
          guidFileName: action.payload.guidFileName,
        },
        status: TaskStatus.pending,
        items,
        completed: 0,
        error: 0,
        pending: action.payload.nbParts,
        total: action.payload.nbParts,
      }

      state.tasks = update(state.tasks, {
        [action.payload.uuid]: { $set: taskState },
      })

      for (const item of items) {
        queueUploadPartTask({
          uuid: action.payload.uuid,
          uploadId: taskState.task.uploadId,
          guidFileName: taskState.task.guidFileName,
          file: files[taskState.task.uploadId],
          nbParts: taskState.task.nbParts,
          partNumber: item.partNumber,
          partSize: partSize,
        })
      }
    })

    builder.addCase(completeUploadTaskAsync.fulfilled, (_state, action) => {
      delete files[action.payload]
    })

    builder.addCase(cancelUploadTaskAsync.fulfilled, (state, action) => {
      delete files[action.payload.uploadId]
      removeUploadTask(state, action.payload.uuid)
    })

    builder.addCase(deleteUploadTaskAsync.fulfilled, (state, action) => {
      delete files[action.payload.uploadId]
      removeUploadTask(state, action.payload.uuid)
    })
  },
})

export const {
  setUploadStatus,
  setUploadPartStatus,
  resetUploadTasks,
  setIsInternal,
  setIsReference,
  setIsMain,
  setIsClean,
  setIsFinal,
} = uploadManagerSlice.actions

export default uploadManagerSlice.reducer
