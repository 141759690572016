import Button from "@mui/material/Button"
import styled from "styled-components"

const MuiButton = styled(Button)(({ theme: { palette } }) => ({
  "&&": {
    fontSize: "1rem",
    "&.Mui-disabled": {
      color: "#fff",
      backgroundColor: "#E0E0E0",
    },
    "&.MuiButton-sizeSmall": {
      fontSize: ".8rem",
    },
    "&.MuiButton-sizeLarge": {
      fontSize: "1.2rem",
    },
    "&:not(.Mui-disabled).MuiButton": {
      "&-containedPrimary": {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        "&:hover": {
          backgroundColor: palette.primary.over,
        },
      },
      "&-containedSecondary": {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        " &:hover": {
          backgroundColor: palette.secondary.over,
        },
      },
      "&-textPrimary": {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.over,
        },
      },
      "&-textSecondary": {
        color: palette.secondary.main,
        "&:hover": {
          color: palette.secondary.over,
        },
      },
      "&-outlinedPrimary": {
        border: `1px solid ${palette.primary.main}`,
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.over,
        },
      },
      "&-outlinedSecondary": {
        border: `1px solid ${palette.secondary.main}`,
        color: palette.secondary.main,
        "&:hover": {
          color: palette.secondary.over,
        },
      },
    },
    "&.MuiButton-Edit": {
      borderRadius: "7px",
      fontSize: "1rem",
      fontWeight: "bold",
      padding: "4px 16px",
      "&.Mui-disabled": {
        fontStyle: "italic",
      },
    },
  },
}))

export default MuiButton
