import CssBaseline from "@mui/material/CssBaseline"
import React from "react"
import { Outlet } from "react-router-dom"

import GlobalStyle from "@/components/GlobalStyle"
import {
  AppContent,
  Drawer,
  MainContent,
  Root,
  drawerWidth,
} from "@/components/layouts/DashboardLayout/DashboardLayout.styled"
import Navbar from "@/components/navbar/NavBar"
import dashboardItems from "@/components/sidebar/dashboardItems"
import Sidebar from "@/components/sidebar/Sidebar"
import UiConfig from "@/components/UiConfig"

const DashboardLayout: React.FC = () => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Sidebar
          PaperProps={{ style: { width: drawerWidth } }}
          items={dashboardItems}
          hideFooter
        />
      </Drawer>
      <AppContent>
        <UiConfig />
        <Navbar />
        <MainContent
          sx={{
            p: 6,
          }}
        >
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  )
}

export default DashboardLayout
