import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Toolbar from "@mui/material/Toolbar"
import React, { useCallback, useEffect, useState } from "react"
import { withTheme } from "styled-components"

import Breadcrumb from "@/components/Breadcrumb"
import GlobalSearchBar from "@/components/navbar/NavBar/GlobalSearchBar"
import { AppBar } from "@/components/navbar/NavBar/NavBar.styled"
import NavbarUserDropdown from "@/components/navbar/NavbarUserDropdown"
import StopVirtualAccessButton from "@/components/virtualAccess/StopVirtualAccessButton"
import useAuth from "@/hooks/useAuth"

export type NavbarProps = {
  theme: {}
}
const root = document.getElementById("root")

const Navbar: React.FC<NavbarProps> = () => {
  const [contentScrolled, setContentScrolled] = useState(false)
  const { user } = useAuth()

  const scrollHandler = useCallback(() => {
    if (root) {
      root.scrollTop > 40 ? setContentScrolled(true) : setContentScrolled(false)
    }
  }, [setContentScrolled])

  useEffect(() => {
    if (root) {
      root.addEventListener("scroll", scrollHandler)
      return () => root.removeEventListener("scroll", scrollHandler)
    }
  }, [scrollHandler])

  return (
    <AppBar position="sticky" elevation={0} $contentScrolled={contentScrolled}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item>
            <Breadcrumb />
          </Grid>
          <Grid item xs />
          {user?.virtual_email && (
            <Grid item>
              <Box sx={{ mr: 2 }}>
                <StopVirtualAccessButton />
              </Box>
            </Grid>
          )}
          <Grid item>
            <GlobalSearchBar />
          </Grid>
          <Grid item>
            <NavbarUserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default withTheme(Navbar)
