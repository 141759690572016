import authEn from "@/i18n/en/auth.json"
import commonEn from "@/i18n/en/common.json"
import enumsEn from "@/i18n/en/enums.json"
import formEn from "@/i18n/en/form.json"
import messageEn from "@/i18n/en/message.json"
import authFr from "@/i18n/fr/auth.json"
import commonFr from "@/i18n/fr/common.json"
import enumsFr from "@/i18n/fr/enums.json"
import formFr from "@/i18n/fr/form.json"
import messageFr from "@/i18n/fr/message.json"

const resources = {
  en: {
    auth: authEn,
    common: commonEn,
    enums: enumsEn,
    form: formEn,
    message: messageEn,
  },
  fr: {
    auth: authFr,
    common: commonFr,
    enums: enumsFr,
    form: formFr,
    message: messageFr,
  },
} as const

export default resources
