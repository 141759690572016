import * as React from "react"
import { useCallback, useMemo } from "react"

import { FullConfig } from "@/graphql"

type ConfigContextType = {
  setUiConfig: React.Dispatch<React.SetStateAction<any[] | undefined>>
  setForceRefreshUiConfig: (_: () => () => Promise<void>) => void
  forceRefreshUiConfig: () => () => Promise<void>
  getConfigNumber: (category: string, key: string) => number | undefined
  getConfigString: (category: string, key: string) => string | undefined
  getConfigBoolean: (category: string, key: string) => boolean | undefined
  getConfigEnum: (category: string, key: string) => any | undefined
}

export const ConfigContext = React.createContext<ConfigContextType | undefined>(
  undefined,
)

type ConfigProviderProps = {
  children: React.ReactNode
}

type ConfigValueType = number | string | boolean

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [uiConfig, setUiConfig] = React.useState<FullConfig[]>()

  const [forceRefreshUiConfig, setForceRefreshUiConfig] = React.useState<
    () => () => Promise<void>
  >(() => async () => {})

  const getConfig = useCallback(
    <T extends ConfigValueType>(
      category: string,
      key: string,
    ): T | undefined => {
      const config = uiConfig?.find((config) => config.name === category)
      if (!config) return undefined
      return config.values[key] as T
    },
    [uiConfig],
  )

  const getConfigNumber = useCallback(
    (category: string, key: string) => {
      return getConfig<number>(category, key)
    },
    [getConfig],
  )

  const getConfigString = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const getConfigBoolean = useCallback(
    (category: string, key: string) => {
      return getConfig<boolean>(category, key)
    },
    [getConfig],
  )

  const getConfigEnum = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const contextValue: ConfigContextType = useMemo(() => {
    return {
      setUiConfig,
      setForceRefreshUiConfig,
      forceRefreshUiConfig,
      getConfigNumber,
      getConfigString,
      getConfigBoolean,
      getConfigEnum,
    }
  }, [
    setUiConfig,
    setForceRefreshUiConfig,
    forceRefreshUiConfig,
    getConfigNumber,
    getConfigString,
    getConfigBoolean,
    getConfigEnum,
  ])

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  )
}
