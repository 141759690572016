import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useCallback, useState } from "react"
import { Trans } from "react-i18next"

import Button from "@/components/Button"
import useAuth from "@/hooks/useAuth"
import useIdleTimeout from "@/hooks/useIdleTimeout"

const SessionIdleDialog: React.FC = () => {
  const { isAuthenticated, signOut } = useAuth()
  const [isIdleDialogOpen, setIsIdleDialogOpen] = useState(false)

  const idleHandler = useCallback(() => {
    setIsIdleDialogOpen(true)
  }, [setIsIdleDialogOpen])

  const closeHandler = useCallback(() => {
    signOut()
  }, [signOut])

  const { idleTimer } = useIdleTimeout({
    disabled: !isAuthenticated,
    signOut,
    onIdle: idleHandler,
  })

  const continueHandler = useCallback(() => {
    idleTimer.reset()
    setIsIdleDialogOpen(false)
  }, [setIsIdleDialogOpen, idleTimer])

  return (
    <Dialog
      open={isIdleDialogOpen}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans>SessionIdle</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans>SessionAboutToExpire</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>
          <Trans>Logout</Trans>
        </Button>
        <Button onClick={continueHandler} autoFocus>
          <Trans>Continue</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SessionIdleDialog
