import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  split,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link"
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link"
import { createClient as createWsClient } from "graphql-ws"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { loadConfig, registerSignOutProc } from "@/contexts/CognitoContext"
import { SnackbackOpenProps } from "@/contexts/SnackbarContext"
import useAuth from "@/hooks/useAuth"
import useSnackbar from "@/hooks/useSnackbar"
import { globalErrorHandler } from "@/utils/globalErrorHandler"

let client: ApolloClient<NormalizedCacheObject>

export const getClient = () => {
  return client
}

const createClient = (
  getToken: () => Promise<string>,
  openSnackbar: Dispatch<SetStateAction<SnackbackOpenProps>>,
  t: TFunction<"form" | "auth" | "common" | "enums" | "message", undefined>,
): ApolloClient<NormalizedCacheObject> => {
  const config = loadConfig()
  if (!config)
    throw new Error("No config found when initializing graphql client")

  const httpLink = new HttpLink({
    uri: config.graphqlApiUri,
  })

  const wsLink =
    process.env.NODE_ENV === "development"
      ? new GraphQLWsLink(
          createWsClient({
            url: config.graphqlApiUri
              .replace("http", "ws")
              .replace(":3000", ":4000"),
          }),
        )
      : createSubscriptionHandshakeLink(
          {
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: getToken,
            },
            region: config.awsRegion,
            url: config.graphqlApiUri.replace("https://", "wss://"),
          },
          httpLink,
        )

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      onError((error) => {
        return globalErrorHandler(error, openSnackbar, t)
      }),
      createAuthLink({
        url: config.graphqlApiUri,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getToken,
        },
        region: config.awsRegion,
      }),
      split(
        (op) => {
          const { operation } = op.query.definitions[0] as any
          return operation !== "subscription"
        },
        httpLink,
        wsLink,
      ),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  })
}

const signOut = async () => {
  await client.clearStore()
}

export const GraphqlProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { getToken } = useAuth()
  const { openSnackbar } = useSnackbar()
  const { t } = useTranslation()
  if (!client) {
    client = createClient(getToken, openSnackbar, t)
    registerSignOutProc("graphql", signOut)
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
