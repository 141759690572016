import { Trans } from "react-i18next"

import { Claim, Right } from "@/claims"
import Alert from "@/components/Alert"
import useClaim from "@/hooks/useClaim"

type Props = {
  claim: Claim
  right: Right
  empty?: boolean
}

const ClaimGuard: React.FC<React.PropsWithChildren<Props>> = ({
  claim,
  right,
  empty,
  children,
}) => {
  const canRender = useClaim(claim, right)

  if (!canRender) {
    if (empty) return <></>
    return (
      <Alert severity="error">
        <Trans>AccessDenied</Trans>
      </Alert>
    )
  }

  return children
}

export default ClaimGuard
