import React from "react"
import { Link } from "react-router-dom"

import { LogoSymbolStyled } from "@/components/logo/LogoSymbol/LogoSymbol.styled"

const preventDrag: React.MouseEventHandler<HTMLDivElement> = (event) =>
  event.preventDefault()

const LogoSvg: React.FC = () => {
  return (
    <LogoSymbolStyled onMouseDown={preventDrag}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 331.81 327.63"
        xmlSpace="preserve"
      >
        <style type="text/css">{".st2{fill:#FFFFFF;}"}</style>
        <path
          className="st2"
          d="M248.67,170.04c0-0.09-0.03-0.14-0.04-0.23c-0.01-0.31-0.08-0.61-0.16-0.91c-0.02-0.06-0.02-0.18-0.04-0.23
	c-4.31-33.18-32.87-58.2-66.43-58.2h-39.06l-12.47-11.1c-11.89-10.58-27.2-16.41-43.11-16.42c-0.02,0-0.05-0.01-0.07-0.01
	c-2.54,0-4.61,2.07-4.61,4.61v43.81v3.32v42.9c0,37.01,30.11,67.12,67.12,67.12H182c11.13,0,22.19-2.82,31.99-8.15
	c0.03-0.02,0.09-0.07,0.14-0.11c0.4-0.22,0.82-0.47,1.24-0.7c0.74-0.41,1.48-0.81,2.2-1.25c19.75-12.42,31.54-33.7,31.54-56.91
	C249.12,175,248.97,172.51,248.67,170.04z M91.98,142.21c1.16,0.88,2.35,1.78,3.46,2.78l97.36,86.65c0.97,0.81,1.93,1.46,2.88,2.03
	c-4.52,1.09-9.1,1.73-13.68,1.73h-32.2c-31.89,0-57.83-25.94-57.83-57.83V142.21z M212.68,226.68c-0.24,0.01-0.49,0.04-0.65,0.09
	c-4.26,1.38-9.1,0.36-12.61-2.65l-97.34-86.64c-2.97-2.69-6.39-5.17-10.11-7.4V92.39c11.97,1,23.32,5.83,32.36,13.87l88.77,78.99
	c3.95,3.44,8.96,5.18,13.98,5.18c4.2,0,8.4-1.25,11.99-3.73C236.49,203.21,226.98,217.71,212.68,226.68z M238.25,173.03
	c-0.7,2.38-2.15,4.44-4.18,5.95c-4.39,3.18-10.78,2.89-14.86-0.66l-65.91-58.65H182c28.72,0,53.37,21.44,57.37,49.79L238.25,173.03z
	"
        />
      </svg>
    </LogoSymbolStyled>
  )
}

const LogoSymbol: React.FC = ({ ...props }) => (
  <Link to="/" aria-label="Logo Broca">
    <LogoSvg {...props} />
  </Link>
)

export default LogoSymbol
