import { Alert } from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import * as React from "react"
import { useCallback, useEffect } from "react"

import useSnackbar from "@/hooks/useSnackbar"

const defaultDuration = 6000

const Snack: React.FC = () => {
  const { open, message, severity, _setOpen } = useSnackbar()

  const handleClose = useCallback(() => {
    _setOpen(false)
  }, [_setOpen])

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose()
      }, defaultDuration)
      return () => clearTimeout(timer)
    }
  }, [open, handleClose])

  return (
    <Snackbar
      open={open}
      autoHideDuration={defaultDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity ?? "success"}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Snack
