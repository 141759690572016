import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation } from "react-router-dom"

import SessionIdleDialog from "@/components/SessionIdleDialog"
import useAuth from "@/hooks/useAuth"

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAuth()
  const { t } = useTranslation()
  const location = useLocation()

  if (!isInitialized) {
    return <>{t("Initializing")}</>
  } else if (!isAuthenticated) {
    if (location.pathname === "/") {
      return <Navigate to={"/auth/signIn"} />
    }

    const encodedRedirect = encodeURIComponent(location.pathname)
    const newUrl = `/auth/signIn?redirect=${encodedRedirect}`
    return <Navigate to={newUrl} />
  }

  return (
    <>
      {children}
      <SessionIdleDialog />
    </>
  )
}

export default AuthGuard
