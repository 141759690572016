import useTheme from "@mui/material/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import React from "react"
import ReactPerfectScrollbar from "react-perfect-scrollbar"
import styled, { css } from "styled-components"

import SidebarNavList from "@/components/sidebar/SidebarNavList"
import { SidebarItemsType } from "@/types/sidebar"

const baseScrollbar = css`
  height: 100%;
  background-color: ${(props) => props.theme.sidebar.background};
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

type SidebarNavProps = {
  items: SidebarItemsType[]
}

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType

  return (
    <ScrollbarComponent>
      <SidebarNavList items={items} />
    </ScrollbarComponent>
  )
}

export default SidebarNav
