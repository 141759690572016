import { MenuItem as MuiMenuItem } from "@mui/material"
import styled from "styled-components"

const MenuItemWrapper = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.palette.secondary.over};
  transition: color 0.3s;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

const MenuItem: React.FC<React.PropsWithChildren<any>> = ({
  children = "",
  ...props
}) => {
  return (
    <MenuItemWrapper>
      <MuiMenuItem {...props}>
        <strong>{children}</strong>
      </MuiMenuItem>
    </MenuItemWrapper>
  )
}

export default MenuItem
