import React, { createContext, useState } from "react"

import { THEMES } from "@/constants"

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (_theme: string) => {
    /**/
  },
}
const ThemeContext = createContext(initialState)

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const initTheme = () => {
    const storedTheme = localStorage.getItem("theme")
    return storedTheme ? JSON.parse(storedTheme) : THEMES.DEFAULT
  }

  const [theme, _setTheme] = useState<string>(initTheme())

  const setTheme = (newTheme: string) => {
    localStorage.setItem("theme", JSON.stringify(newTheme))
    _setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
