import i18next, { use } from "i18next"
import { initReactI18next } from "react-i18next"
import * as Yup from "yup"

import resources from "@/@types/i18nResources"

enum SupportedLng {
  EN = "en",
  FR = "fr",
}

type ResourceNamespace = keyof (typeof resources)[SupportedLng.FR]

export const defaultNS: ResourceNamespace = "common"

const language = localStorage.getItem("lng") ?? SupportedLng.FR

use(initReactI18next).init({
  lng: language,
  supportedLngs: [SupportedLng.EN, SupportedLng.FR] as SupportedLng[],
  fallbackLng: SupportedLng.FR,
  ns: ["auth", "common", "form", "message"] as ResourceNamespace[],
  compatibilityJSON: "v4",
  interpolation: {
    escapeValue: false,
  },
  resources,
  defaultNS,
})

/* eslint-disable no-template-curly-in-string */
const yupFrLocale: Yup.LocaleObject = {
  mixed: {
    default: "Champ invalide.",
    required: "Champ requis",
    defined: "Doit être défini",
    notNull: "Ne peut pas être nul",
    oneOf: "Doit être l'une des valeurs suivantes: ${values}",
    notOneOf: "Ne doit pas être l'une des valeurs suivantes: ${values}",
    notType: ({ type, value, originalValue }) => {
      const isCast = originalValue != null && originalValue !== value
      let msg =
        `Doit être un type \`${type}\`, ` +
        `Mais la valeur finale était: \`${Yup.printValue(value, true)}\`` +
        (isCast
          ? ` (coulé à partir de la valeur \`${Yup.printValue(
              originalValue,
              true,
            )}\`).`
          : ".")

      if (value === null) {
        msg +=
          `\n Si "null" est conçu comme une valeur vide, assurez-vous de marquer le schéma comme` +
          " `.nullable()`"
      }

      return msg
    },
  },
  string: {
    length: "Doit être exactement ${length} caractères",
    min: "Doit être au moins ${min} caractères",
    max: "Doit être au plus ${max} caractères",
    matches: 'Doit correspondre à ce qui suit: "${regex}"',
    email: "Doit être un e-mail valide",
    url: "Doit être une URL valide",
    uuid: "Doit être un uuid valide",
    trim: "Doit être une chaîne taillée",
    lowercase: "Doit être une chaîne en minuscules",
    uppercase: "Doit être une chaîne en majuscules",
  },
  number: {
    min: "Doit être supérieur ou égal à ${min}",
    max: "Doit être inférieur ou égal à ${max}",
    lessThan: "Doit être inférieur à ${less}",
    moreThan: "Doit être supérieur à ${more}",
    positive: "Doit être un nombre positif",
    negative: "Doit être un nombre négatif",
    integer: "Doit être un entier",
  },
  date: {
    min: "Doit être plus tard que ${min}",
    max: "Doit être plus tôt que ${max}",
  },
  boolean: {
    isValue: "Doit être ${value}",
  },
  object: {
    noUnknown:
      "Ne peut pas avoir des clés non spécifiées dans la forme de l'objet",
  },
  array: {
    min: "Doit avoir au moins ${min} des articles",
    max: "Doit avoir moins ou égal à ${max}",
    length: "Doit avoir ${length} des articles",
  },
}

const yupEnLocale: Yup.LocaleObject = {
  mixed: {
    default: "Invalid field",
    required: "Required field",
    defined: "Must be defined",
    notNull: "Cannot be null",
    oneOf: "Must be one of the following values: ${values}",
    notOneOf: "Must not be one of the following values: ${values}",
    notType: ({ type, value, originalValue }) => {
      const castMsg =
        originalValue != null && originalValue !== value
          ? ` (cast from the value \`${Yup.printValue(originalValue, true)}\`).`
          : "."

      return type !== "mixed"
        ? `Must be a \`${type}\` type, ` +
            `but the final value was: \`${Yup.printValue(value, true)}\`` +
            castMsg
        : `Must match the configured type. ` +
            `The validated value was: \`${Yup.printValue(value, true)}\`` +
            castMsg
    },
  },
  string: {
    length: "Must be exactly ${length} characters",
    min: "Must be at least ${min} characters",
    max: "Must be at most ${max} characters",
    matches: 'Must match the following: "${regex}"',
    email: "Must be a valid email",
    url: "Must be a valid URL",
    uuid: "Must be a valid UUID",
    trim: "Must be a trimmed string",
    lowercase: "Must be a lowercase string",
    uppercase: "Must be a upper case string",
  },
  number: {
    min: "Must be greater than or equal to ${min}",
    max: "Must be less than or equal to ${max}",
    lessThan: "Must be less than ${less}",
    moreThan: "Must be greater than ${more}",
    positive: "Must be a positive number",
    negative: "Must be a negative number",
    integer: "Must be an integer",
  },
  date: {
    min: "Must be later than ${min}",
    max: "Must be at earlier than ${max}",
  },
  boolean: {
    isValue: "Must be ${value}",
  },
  object: {
    noUnknown: "Has unspecified keys: ${unknown}",
  },
  array: {
    min: "Must have at least ${min} items",
    max: "Must have less than or equal to ${max} items",
    length: "Must have ${length} items",
  },
}

/* eslint-enable no-template-curly-in-string */
if (language.startsWith("en")) {
  Yup.setLocale(yupEnLocale)
} else {
  Yup.setLocale(yupFrLocale)
}

i18next.on("languageChanged", (lng) => {
  localStorage.setItem("lng", lng)
  if (lng.startsWith("en")) {
    Yup.setLocale(yupEnLocale)
  } else {
    Yup.setLocale(yupFrLocale)
  }
})
