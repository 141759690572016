import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Button from "@/components/Button"
import ClaimGuard from "@/components/ClaimGuard"
import { useStopVirtualAccessMutation } from "@/graphql"
import useAuth from "@/hooks/useAuth"

const StopVirtualAccessButton: React.FC = () => {
  const { t } = useTranslation()
  const { getToken } = useAuth()
  const navigate = useNavigate()
  const [stopVirtualAccess] = useStopVirtualAccessMutation()

  const clickHandler = useCallback(async () => {
    await stopVirtualAccess()
    await getToken(undefined, true)
    navigate("/")
  }, [getToken, navigate, stopVirtualAccess])

  return (
    <ClaimGuard claim="VirtualAccess" right="Write" empty={true}>
      <Button color="error" onClick={clickHandler}>
        {t("VirtualAccess.Stop")}
      </Button>
    </ClaimGuard>
  )
}

export default StopVirtualAccessButton
