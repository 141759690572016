import React, { useMemo } from "react"
import { Accept, DropEvent, useDropzone } from "react-dropzone"
import { Trans } from "react-i18next"

import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "@/components/DragDrop/DragDrop.styled"

type Props = {
  label?: string
  maxFiles?: number
  accept?: Accept
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void
}

const DragDrop: React.FC<Props> = ({
  label,
  maxFiles,
  accept,
  onDropAccepted,
}) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDropAccepted,
      maxFiles,
      accept,
    })

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }) as React.CSSProperties,
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {label ?? <Trans>ChooseOrDragFile</Trans>}
    </div>
  )
}

export default DragDrop
