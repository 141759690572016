import React, { useCallback, useEffect } from "react"

import { useGetFrontEndConfigQuery } from "@/graphql"
import useUIConfig from "@/hooks/useUIConfig"
import { readJson } from "@/services/jsonTransform"

const UiConfig: React.FC = () => {
  const { setUiConfig, setForceRefreshUiConfig } = useUIConfig()

  const { data, refetch } = useGetFrontEndConfigQuery()

  const forceRefreshUiConfig = useCallback(async () => {
    refetch()
  }, [refetch])

  useEffect(() => {
    for (const config of data?.getFrontEndConfig || []) {
      if (config) config.values = readJson(config.values)
    }
    setUiConfig(data?.getFrontEndConfig)
  }, [data, setUiConfig])

  useEffect(() => {
    setForceRefreshUiConfig(() => forceRefreshUiConfig)
  }, [forceRefreshUiConfig, setForceRefreshUiConfig])

  return <></>
}

export default UiConfig
