import { AlertProps } from "@mui/material/Alert/Alert"
import * as React from "react"
import { useEffect, useMemo } from "react"

type SnackbarContextType = {
  open: boolean
  message: string
  severity: AlertProps["severity"]
  openSnackbar: React.Dispatch<React.SetStateAction<SnackbackOpenProps>>
  _setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SnackbarContext = React.createContext<
  SnackbarContextType | undefined
>(undefined)

type SnackbarProviderProps = {
  children: React.ReactNode
}

export type SnackbackOpenProps = {
  message: string
  severity?: AlertProps["severity"]
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [open, _setOpen] = React.useState(false)
  const [snackProps, openSnackbar] = React.useState<SnackbackOpenProps>({
    message: "",
    severity: "success",
  })

  useEffect(() => {
    if (snackProps.message) {
      _setOpen(true)
    }
  }, [snackProps])

  const contextValue: SnackbarContextType = useMemo(() => {
    return {
      open,
      message: snackProps.message,
      severity: snackProps.severity,
      _setOpen,
      openSnackbar,
    }
  }, [open, snackProps, _setOpen, openSnackbar])

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
    </SnackbarContext.Provider>
  )
}
