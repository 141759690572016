import { useContext, useMemo } from "react"

import { Claim, Right } from "@/claims"
import { AuthContext } from "@/contexts/CognitoContext"

const rightsOrder: Right[] = ["Read", "Write", "Delete", "Admin"]

const useClaim = (claim: Claim, right: Right, throwable?: boolean) => {
  const context = useContext(AuthContext)

  return useMemo(() => {
    const groups = context?.access?.["cognito:groups"] as string[]
    if (!groups) return false

    const availableRights = rightsOrder
      .slice(rightsOrder.indexOf(right))
      .map((r) => `${claim}${r}`)

    const result = groups.some((c) => availableRights.includes(c))
    if (throwable && !result) {
      throw new Error(`Permission denied: ${claim}${right}`)
    }

    return result
  }, [context, claim, right, throwable])
}

export default useClaim
